import React from 'react'
import starIcon from '../../assets/images/star-icon.png'
import howItWork from '../../assets/images/how-its-work.png'
import { useIntl } from "gatsby-plugin-intl"

const WhyChooseUs = () => {
    const intl = useIntl()
    return (
        <section className="how-its-work-area ptb-100">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-6 col-md-12">
                        <div className="how-its-work-content">
                            <span className="sub-title">
                                <img src={starIcon} alt="banner" /> 
                                {intl.formatMessage({ id: "aboutUs.WhyChooseUs.SubTitle" })}
                            </span>
                            <h2>{intl.formatMessage({ id: "aboutUs.WhyChooseUs.H2SubTitle" })}</h2>
                            <p>{intl.formatMessage({ id: "aboutUs.WhyChooseUs.PSubTitle" })}</p>
                            <div className="inner-box">
                                <div className="single-item">
                                    <div className="count-box">
                                        1
                                    </div>
                                    <h3>{intl.formatMessage({ id: "aboutUs.WhyChooseUs.Name1" })}</h3>
                                    <p>{intl.formatMessage({ id: "aboutUs.WhyChooseUs.Content1" })}</p>
                                </div>
                                <div className="single-item">
                                    <div className="count-box">
                                        2
                                    </div>
                                    <h3>{intl.formatMessage({ id: "aboutUs.WhyChooseUs.Name2" })}</h3>
                                    <p>{intl.formatMessage({ id: "aboutUs.WhyChooseUs.Content2" })}</p>
                                </div>
                                <div className="single-item">
                                    <div className="count-box">
                                        3
                                    </div>
                                    <h3>{intl.formatMessage({ id: "aboutUs.WhyChooseUs.Name3" })}</h3>
                                    <p>{intl.formatMessage({ id: "aboutUs.WhyChooseUs.Content3" })}</p>
                                </div>
                                <div className="single-item">
                                    <div className="count-box">
                                        4
                                    </div>
                                    <h3>{intl.formatMessage({ id: "aboutUs.WhyChooseUs.Name4" })}</h3>
                                    <p>{intl.formatMessage({ id: "aboutUs.WhyChooseUs.Content4" })}</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-6 col-md-12">
                        <div className="how-its-work-image">
                            <img src={howItWork} alt="banner" />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default WhyChooseUs