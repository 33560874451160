import React from 'react'
import { Link } from 'gatsby'
import aboutImage from '../../assets/images/about/about-img5.png'
import starIcon from '../../assets/images/star-icon.png'
import icon4 from '../../assets/images/icons/icon4.png'
import icon5 from '../../assets/images/icons/icon5.png'
import icon6 from '../../assets/images/icons/icon6.png'
import icon7 from '../../assets/images/icons/icon7.png'
import shape1 from '../../assets/images/shape/circle-shape1.png'
import { useIntl } from "gatsby-plugin-intl"
 
const AboutUsContent = () => {
    const intl = useIntl()
    return (
        <section className="about-area ptb-100">
            <div className="container-fluid">
                <div className="row align-items-center">
                    <div className="col-lg-6 col-md-12">
                        <div className="about-image">
                            <img src={aboutImage} alt="banner" />
                        </div>
                    </div>

                    <div className="col-lg-6 col-md-12">
                        <div className="about-content">
                            <div className="content">
                                <span className="sub-title">
                                    <img src={starIcon} alt="banner" /> 
                                    {intl.formatMessage({ id: "aboutUs.AboutUsContent.SubTitle" })}
                                </span>
                                <h2>{intl.formatMessage({ id: "aboutUs.AboutUsContent.H2SubTitle" })}</h2>
                                <p>{intl.formatMessage({ id: "aboutUs.AboutUsContent.PSubTitle1" })}</p>
                                
                                <ul className="features-list">
                                    <li>
                                        <img src={icon4} alt="banner" />
                                        <h3>5 Years</h3>
                                        <p>On the market</p>
                                    </li>
                                    <li>
                                        <img src={icon5} alt="banner" />
                                        <h3>15+</h3>
                                        <p>Team members</p>
                                    </li>
                                    <li>
                                        <img src={icon6} alt="banner" />
                                        <h3>100%</h3>
                                        <p>Satisfaction rate</p>
                                    </li>
                                    <li>
                                        <img src={icon7} alt="banner" />
                                        <h3>70%</h3>
                                        <p>Senior developers</p>
                                    </li>
                                </ul>
                                <p>{intl.formatMessage({ id: "aboutUs.AboutUsContent.PSubTitle2" })}</p>
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="circle-shape1">
                <img src={shape1} alt="banner" />
            </div>

            <div className="container">
                <div className="about-inner-area">
                    <div className="row">
                        <div className="col-lg-4 col-md-6 col-sm-6">
                            <div className="about-text">
                                <h3>{intl.formatMessage({ id: "aboutUs.AboutUsContent.Name1" })}</h3>
                                <p>{intl.formatMessage({ id: "aboutUs.AboutUsContent.Content1" })}</p>
                            </div>
                        </div>
    
                        <div className="col-lg-4 col-md-6 col-sm-6">
                            <div className="about-text">
                                <h3>{intl.formatMessage({ id: "aboutUs.AboutUsContent.Name2" })}</h3>
                                <p>{intl.formatMessage({ id: "aboutUs.AboutUsContent.Content2" })}</p>
                            </div>
                        </div>
    
                        <div className="col-lg-4 col-md-6 col-sm-6 offset-lg-0 offset-md-3 offset-sm-3">
                            <div className="about-text">
                                <h3>{intl.formatMessage({ id: "aboutUs.AboutUsContent.Name3" })}</h3>
                                <p>{intl.formatMessage({ id: "aboutUs.AboutUsContent.Content3" })}</p>
                                
                                <ul className="features-list">
                                    <li><i className="flaticon-tick"></i> {intl.formatMessage({ id: "aboutUs.AboutUsContent.Content3.SubContent1" })}</li>
                                    <li><i className="flaticon-tick"></i> {intl.formatMessage({ id: "aboutUs.AboutUsContent.Content3.SubContent2" })}</li>
                                    <li><i className="flaticon-tick"></i> {intl.formatMessage({ id: "aboutUs.AboutUsContent.Content3.SubContent3" })}</li>
                                    <li><i className="flaticon-tick"></i> {intl.formatMessage({ id: "aboutUs.AboutUsContent.Content3.SubContent4" })}</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="circle-shape1">
                <img src={shape1} alt="banner" />
            </div>
        </section>
    )
}

export default AboutUsContent;