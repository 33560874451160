import React from 'react'
import Layout from "../components/App/Layout"
import NavbarTwo from "../components/App/NavbarTwo"
import PageBanner from '../components/Common/PageBanner'
import Footer from "../components/App/Footer"
import AboutUsContent from '../components/AboutUs/AboutUsContent'
import WhyChooseUs from '../components/AboutUs/WhyChooseUs'
import Testimonials from '../components/AboutUs/Testimonials'
import Partner from '../components/AboutUs/Partner'
import { useIntl } from "gatsby-plugin-intl"
 
const AboutUs = () => {
    const intl = useIntl()
    return (
        <Layout>
            <NavbarTwo bgWhite={false}/>
            <PageBanner
                pageTitle={intl.formatMessage({ id: "aboutUs" })}
                homePageText={intl.formatMessage({ id: "home" })}
                homePageUrl="/" 
                activePageText={intl.formatMessage({ id: "aboutUs" })}
            />
            <AboutUsContent />
            <WhyChooseUs />
            <Testimonials />
            <Partner />
            <Footer />
        </Layout>
    );
}

export default AboutUs;